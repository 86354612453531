import * as React from 'react';

function Svg08(props) {
  return (
    <svg
      id="08_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 246.95 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.08_svg__st0{fill:#020203}'}</style>
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24z" />
      <path fill={props.fill}
        
        d="M164.27 48.48c-.43-2.22 1.87-2.81 2.73-1.19 2.47 4.6 7.41 8.52 15.17 8.52 6.99 0 12.36-5.2 12.36-13.04 0-6.99-4.09-12.61-11.5-13.21-1.53-.09-1.45-2.22.09-2.64 5.11-1.28 8.61-5.11 8.61-10.14 0-6.39-3.75-9.97-9.89-9.97-5.54 0-10.74 2.98-12.78 7.41-.77 1.62-2.9.85-2.47-1.11C168.21 5.7 175.54.33 185.08.33c8.69 0 15.85 5.03 15.85 14.32 0 4.86-2.56 9.12-7.41 11.93 5.45 1.53 10.14 9.37 10.14 16.36 0 11.33-8.27 18.92-20.28 18.92-9.48 0-17.41-5.45-19.11-13.38zM227.21 58.88c0 1.36-.68 2.22-2.05 2.22h-5.88c-1.36 0-2.13-.85-2.13-2.22 0-16.87 4.18-25.31 22.33-47.55.51-.68.26-1.53-.94-1.53h-21.82c-5.28 0-7.67 2.05-10.14 6.39-.94 1.53-2.73.77-2.3-.68l3.07-11.33c.68-2.3 1.88-3.07 4.43-3.07h31.45c3.66 0 4.77 2.81 2.64 6.22-13.8 22.49-18.66 34.16-18.66 51.55z"
      />
    </svg>
  );
}

export default Svg08;
