import * as React from 'react';

function Svg06(props) {
  return (
    <svg
      id="06_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 239.37 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.06_svg__st0{fill:#020203}'}</style>
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24z" />
      <path fill={props.fill}
        
        d="M166.46 52.64V41.69h4.16v5.83c0 3.09.89 5.53 2.68 7.31 1.78 1.79 4.2 2.68 7.23 2.68h6.71c1.76 0 3.34-.37 4.76-1.12 1.41-.75 2.52-1.78 3.32-3.12.8-1.33 1.2-2.8 1.2-4.4V41.6c0-1.6-.39-3.06-1.16-4.4-.77-1.33-1.77-2.37-3-3.12a7.772 7.772 0 00-4.08-1.12h-9.27v-3.6h9.27c1.49 0 2.85-.39 4.08-1.16 1.22-.77 2.22-1.81 3-3.12.77-1.31 1.16-2.78 1.16-4.44V13.4c0-1.6-.4-3.06-1.2-4.4-.8-1.33-1.91-2.37-3.32-3.12s-3-1.12-4.76-1.12h-6.71c-3.09 0-5.51.89-7.27 2.68-1.76 1.79-2.64 4.33-2.64 7.63v5.51h-4.16V9.64l7.83-8.47h18.94l7.35 7.99v15.66l-5.91 6.31 5.91 6.31V53.1l-7.83 7.99h-18.46l-7.83-8.45zM233.7 4.77h-30.21v-3.6h35.88l-23.82 59.94h-4.48L233.7 4.77z"
      />
    </svg>
  );
}

export default Svg06;
