import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import { Link } from 'gatsby';
import {
  INNER_MARGIN,
  BODY_FONT_SMALL,
  SITE_PADDING_TOP,
  SITE_PADDING_TOP_MOBILE,
  SITE_PADDING_LEFT,
  SITE_PADDING_LEFT_MOBILE,
} from '@config/layout';
import { Logo } from './Logo';
import { mobileMQ } from '@config/mediaQueries';
import UIStore from '@stores/UIStore';

const Container = styled.div(
  adaptForLargeScreen({
    width: '100vw',
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: 3,
    pointerEvents: 'none',
  }),
);

const Menu = styled.div(
  adaptForLargeScreen({
    marginRight: INNER_MARGIN,
    marginTop: 28,
    fontSize: BODY_FONT_SMALL,

    [mobileMQ]: {
      display: 'none',
    },
  }),
);

const menuItemStyles: CSSObject = adaptForLargeScreen({
  marginLeft: 35,
  pointerEvents: 'auto',
  '&[aria-current="page"]': {
    textDecoration: 'underline',
  },
});

const MenuItemLink = styled(Link)(menuItemStyles);
const MenuItemA = styled.a(menuItemStyles);

const MobileMenuButton = styled.a<{ color: string }>(p => ({
  display: 'none',

  [mobileMQ]: {
    display: 'block',
    width: 40,
    height: 40,
    position: 'fixed',
    top: SITE_PADDING_TOP_MOBILE,
    right: 20,
    backgroundColor: p.color,
    pointerEvents: 'auto',
  },
}));

const LogoLink = styled(Link)(
  adaptForLargeScreen({
    pointerEvents: 'auto',
    marginTop: SITE_PADDING_TOP,
    marginLeft: SITE_PADDING_LEFT,

    [mobileMQ]: {
      marginTop: SITE_PADDING_TOP_MOBILE,
      marginLeft: SITE_PADDING_LEFT_MOBILE - 6,
    },
  }),
);

export const Header = () => {
  return (
    <Container>
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      <Menu>
        <MenuItemLink to="/">Home</MenuItemLink>
        <MenuItemLink to="/work">Work</MenuItemLink>
        <MenuItemLink to="/shop">Shop</MenuItemLink>
        <MenuItemLink to="/about">About</MenuItemLink>
        <MenuItemLink to="/contact">Contact</MenuItemLink>
        <MenuItemA target="_blank" href="https://f37foundry.com">
          Fonts
        </MenuItemA>
      </Menu>
      <MobileMenuButton
        onClick={UIStore.showMobileMenu}
        color={UIStore.foregroundColor}
      />
    </Container>
  );
};
