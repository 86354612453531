import * as React from 'react';

function Svg16(props) {
  return (
    <svg
      id="16_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 248.15 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24zM167.73 1.16h26.81c6.26 0 11.24 4.87 11.24 11.14 0 6.07-4.97 11.04-11.24 11.04h-17.27c-4.38 0-7.86 3.48-7.86 7.85 0 4.28 3.48 7.76 7.86 7.76h17.27c6.26 0 11.24 4.87 11.24 11.14 0 6.16-4.97 11.04-11.24 11.04h-26.81v-3.08h26.51c4.38 0 8.05-3.58 8.05-7.95 0-4.38-3.68-8.05-8.05-8.05h-17.36c-6.07 0-10.94-4.77-10.94-10.94 0-6.16 4.87-10.84 10.94-10.84h17.36c4.38 0 8.05-3.68 8.05-8.05 0-4.38-3.68-7.95-8.05-7.95h-26.51V1.16zM236.82 1.16c6.26 0 11.33 4.87 11.33 11.24 0 6.17-5.07 11.14-11.33 11.14h-7.76c-4.47 0-8.15 3.68-8.15 8.15v29.43h-3.48V31.39c0-6.07 4.77-10.74 10.94-10.74h8.05c4.47 0 8.25-3.78 8.25-8.15 0-4.57-3.78-8.25-8.25-8.25h-28.3V1.16h28.7z" />
    </svg>
  );
}

export default Svg16;
