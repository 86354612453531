import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { Link } from 'gatsby';
import UIStore from '@stores/UIStore';

const Container = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'black',
  color: 'white',
  zIndex: 4,
  paddingTop: '25%',
  paddingBottom: '42%',
});

const Menu = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
});

const menuItemStyles: CSSObject = {
  fontSize: 35,
};

const MenuItemLink = styled(Link)(menuItemStyles);
const MenuItemA = styled.a(menuItemStyles);

const CloseButtonContainer = styled.div({
  position: 'absolute',
  bottom: 40,
  textAlign: 'center',
  width: '100%',
});

const CloseButton = styled.a({
  fontFamily: 'F37 Beckett Bold',
  fontSize: 42,
});

export const MobileMenu = () => {
  return (
    <Container>
      <Menu>
        <MenuItemLink onClick={UIStore.hideMobileMenu} to="/">
          Home
        </MenuItemLink>
        <MenuItemLink onClick={UIStore.hideMobileMenu} to="/work">
          Work
        </MenuItemLink>
        <MenuItemLink onClick={UIStore.hideMobileMenu} to="/shop">
          Shop
        </MenuItemLink>
        <MenuItemLink onClick={UIStore.hideMobileMenu} to="/about">
          About
        </MenuItemLink>
        <MenuItemLink onClick={UIStore.hideMobileMenu} to="/contact">
          Contact
        </MenuItemLink>
        <MenuItemA
          onClick={UIStore.hideMobileMenu}
          target="_blank"
          href="https://f37foundry.com"
        >
          Fonts
        </MenuItemA>
      </Menu>

      <CloseButtonContainer>
        <CloseButton onClick={UIStore.hideMobileMenu}>×</CloseButton>
      </CloseButtonContainer>
    </Container>
  );
};
