import * as React from 'react';
import { observer } from 'mobx-react';

import {
  Svg01,
  Svg02,
  Svg03,
  Svg04,
  Svg05,
  Svg06,
  Svg07,
  Svg08,
  Svg09,
  Svg10,
  Svg11,
  Svg12,
  Svg13,
  Svg14,
  Svg15,
  Svg16,
  Svg17,
  Svg18,
  Svg19,
  Svg20,
} from '@components/logos';

import UIStore from '@stores/UIStore';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import styled from 'styled-components';
import { mobileMQ } from '@config/mediaQueries';

const LOGOS = [
  Svg01,
  Svg02,
  Svg03,
  Svg04,
  Svg05,
  Svg06,
  Svg07,
  Svg08,
  Svg09,
  Svg10,
  Svg11,
  Svg12,
  Svg13,
  Svg14,
  Svg15,
  Svg16,
  Svg17,
  Svg18,
  Svg19,
  Svg20,
];

const Container = styled.div(
  adaptForLargeScreen({
    display: 'block',
  }),
);

export const Logo = observer(() => {
  const StyledLogo = styled(LOGOS[UIStore.logoIndex] as any)({
    height: `${49 / (1920 / 100)}vw`,

    [mobileMQ]: {
      height: 40,
    },
  });

  return (
    <Container>
      <StyledLogo fill={UIStore.foregroundColor} />
    </Container>
  );
});
