import * as React from 'react';

function Svg18(props) {
  return (
    <svg
      id="18_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 244.56 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24zM162.99 42.38h4.53c.37 10.54 8.05 17.76 19.79 17.76 4.9 0 9.34-1.29 12.95-3.42-3.33 3.51-8.79 5.09-15.26 5.09-13.5-.01-21.27-7.04-22.01-19.43zm37.46-41.25l-24.97 29.04-1.76-4.25 17.39-20.44h-23.58l-.92-4.35h33.84zM168.72 11.3l-.83-3.98h18.87l-3.42 3.98h-14.62zm38.01 29.32c0 11.01-8.32 17.85-19.42 17.85-10.64 0-17.29-6.66-17.66-16.09h4.9c1.39 8.79 6.66 12.3 12.76 12.3 9.9 0 14.89-7.03 14.89-13.69 0-7.77-4.81-13.6-11.65-14.43l2.87-3.42c8.41 1.67 13.31 8.51 13.31 17.48zm-11.65.65c0-6.1-3.7-9.34-10.82-9.34h-7.58L202.76 1.5l1.66 5.64-18.03 21.09h1.94c7.12.09 11.65 5.36 11.65 12.76 0 6.1-4.07 12.02-12.67 12.02-2.22 0-4.72-.55-6.75-2.4 1.57.55 2.96.83 4.53.83 6.29 0 9.99-4.07 9.99-10.17zM214.5 61.06L232.53 5.2h-23.86l-.83-4.07h31.63l-19.79 59.93h-5.18zm-4.62-49.76l-.83-4.16h20.53l-1.29 4.16h-18.41zm16.83 49.76h-4.81l19.7-59.37 2.96 3.98-17.85 55.39z" />
    </svg>
  );
}

export default Svg18;
