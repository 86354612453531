import * as React from 'react';

function Svg12(props) {
  return (
    <svg
      id="12_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 245.38 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24zM205.98 43.74c0 11.11-8.84 18.19-20.79 18.19-11.79 0-20.88-6.65-22.06-17.18l5.47-1.26c.93 8.42 6.99 13.05 16.59 13.05 9.09 0 14.9-4.97 14.9-12.8 0-8.17-7.49-11.53-15.74-11.53h-5.89v-5.39h5.89c8 0 13.05-4.21 13.05-10.86 0-6.15-5.05-10.27-12.97-10.27-5.81 0-10.52 3.37-12.46 7.75l-4.8-1.85C170.12 4.1 177.02.31 184.6.31c10.61 0 18.69 6.23 18.69 15.66 0 6.57-3.54 11.03-9.18 13.3 7.07 1.93 11.87 6.39 11.87 14.47zM245.38 1.14v4.38l-25.26 55.56h-6.4l24.84-54.72h-33.84V1.14h40.66z" />
    </svg>
  );
}

export default Svg12;
