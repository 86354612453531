import * as React from 'react';

function Svg03(props) {
  return (
    <svg
      id="03_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 244.67 62.25"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.03_svg__st0{fill:#020203}'}</style>
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24z" />
      <path fill={props.fill}
        
        d="M165.34 49.1c0-4.18 2.65-7.09 6.49-7.09 3.67 0 6.58 2.9 6.58 6.49 0 3.67-2.9 6.57-6.58 6.57-1.2 0-2.56-.43-3.42-1.02 2.73 4.01 7.43 6.32 12.64 6.32 8.8 0 12.98-4.95 12.98-15.28 0-10.93-2.73-15.28-13.41-15.28h-2.56V28.1h2.31c9.05 0 12.98-4.27 12.98-14 0-7.68-3.59-11.95-10.25-11.95-5.55 0-10.25 2.73-12.64 7.34.85-.94 2.56-1.62 4.18-1.62 3.67 0 6.58 2.9 6.58 6.49s-2.9 6.58-6.58 6.58c-3.59 0-6.49-2.99-6.49-6.83 0-7.94 7.34-14.09 16.91-14.09 10.59 0 17.59 5.81 17.59 14.52 0 7.77-5.46 13.15-14.94 14.26 11.61.68 17.33 6.57 17.33 15.63 0 10.59-9.14 17.85-22.29 17.85-10.74-.03-17.41-5.24-17.41-13.18zM220.16 53.88c0-9.56 6.06-18.27 11.95-26.56 2.82-4.01 5.64-7.94 7.69-11.87 1.79-3.33 2.99-6.58 3.24-9.82-2.22 3.67-6.66 6.06-12.98 6.06-4.18 0-7.43-1.96-11.95-1.96-4.95 0-8.03 3.67-8.03 8.79v1.79h-1.71V.68h1.71v3.5C211.1 2.73 214.26 0 219.13 0c7 0 10.42 6.4 18.27 6.4 3.84 0 5.64-2.48 5.98-4.61h1.02c.17.77.26 1.37.26 2.31 0 4.18-1.37 8.28-3.24 12.21-4.1 8.62-10.67 16.48-10.67 22.97 0 7 1.88 9.05 1.88 14.6 0 5.98-2.56 8.28-5.89 8.28-4.36 0-6.58-3.33-6.58-8.28z"
      />
    </svg>
  );
}

export default Svg03;
