import * as React from 'react';

function Svg19(props) {
  return (
    <svg
      id="19_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 252.74 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24zM187.39 27.4l-2.38-.06 20.55-21.22-1.24-4.95h-36l-1.09 4.69h32.94l-21.4 22.41 1.04 2.95h6.66c10.73 0 16.64 4.45 16.64 12.54 0 8.52-6.12 13.41-16.79 13.41-7.46 0-14.1-1.41-19.75-4.18l-1.29 4.4c5.91 2.77 13.21 4.23 21.19 4.23 13.55 0 21.64-6.65 21.64-17.78 0-13.17-11.27-16.21-20.72-16.44zM251.05 1.18h-40.62l-1.09 4.68h38.87l-26.69 54.57 5.05 1.11 26.17-55.18z" />
    </svg>
  );
}

export default Svg19;
