import * as React from 'react';

function Svg04(props) {
  return (
    <svg
      id="04_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 242.54 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.04_svg__st0{fill:#020203}'}</style>
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24z" />
      <path fill={props.fill}
        
        d="M164.76 45.02h1.58c.18 9.76 6.5 15.73 16.79 15.73 10.55 0 15.82-5.54 15.82-16.26 0-10.55-6.06-16-17.58-16h-.97V27h.88c8.96 0 14.15-5.01 14.15-13.36 0-7.65-4.48-12.13-12.48-12.13-8.88 0-14.15 4.13-14.94 12.13h-1.49c.7-8.7 6.94-13.62 16.7-13.62 10.81 0 16.96 5.19 16.96 13.53 0 7.21-4.75 12.3-13.01 13.97 10.99 1.58 16.61 7.21 16.61 16.87 0 10.9-8.53 17.84-20.74 17.84-11.25.01-18.28-6.66-18.28-17.21zM238.23 1.96l-35.33.61V.73h39.64L215.3 61.54h-4.13l27.06-59.58z"
      />
    </svg>
  );
}

export default Svg04;
