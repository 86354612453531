import { isNumber } from 'util';
import { CSSObject } from 'styled-components';

import { desktopMaxWidthNumber } from '@config/breakpoints';
import { fullWidthMQ, maxWidthMQ } from '@config/mediaQueries';

const horizontalPropertiesToAdapt = [
  'padding',
  'paddingLeft',
  'paddingRight',
  'margin',
  'marginLeft',
  'marginRight',
  'left',
  'right',
  'columnGap',
  'gridAutoRows',
  'width',
  'maxWidth',
  'minWidth',
  'flexBasis',
  'textIndent',
  'outlineWidth',
  'borderWidth',
  'borderRadius',
  'borderTopLeftRadius',
  'borderBottomLeftRadius',
  'borderTopWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  'borderRightWidth',
  'letterSpacing',
];

const verticalPropertiesToAdapt = [
  'paddingTop',
  'paddingBottom',
  'marginTop',
  'marginBottom',
  'maxHeight',
  'height',
  'top',
  'bottom',
];

const fontPropertiesToAdapt = ['fontSize'];

const propertiesToAdapt = [
  ...horizontalPropertiesToAdapt,
  ...verticalPropertiesToAdapt,
  ...fontPropertiesToAdapt,
];

export const pxToVw = (px: number) => px / (1920 / 100) + 'vw';

export const adaptForLargeScreen = (
  styleObject: CSSObject,
  noMediaQuery = false,
  debug = false,
): CSSObject => {
  if (noMediaQuery && window.innerWidth < desktopMaxWidthNumber)
    return styleObject;

  const adaptedStyle: any = { ...(styleObject as any) };
  let workingStyle: any = { ...(styleObject as any) };

  if (workingStyle[fullWidthMQ]) {
    workingStyle = { ...workingStyle, ...workingStyle[fullWidthMQ] };
  }

  if (!noMediaQuery) adaptedStyle[maxWidthMQ] = {};

  Object.keys(workingStyle).forEach(key => {
    if (!propertiesToAdapt.includes(key)) return;
    if (!isNumber(workingStyle[key])) return;

    if (
      horizontalPropertiesToAdapt.includes(key) ||
      verticalPropertiesToAdapt.includes(key)
    ) {
      if (noMediaQuery) adaptedStyle[key] = pxToVw(workingStyle[key]);
      else adaptedStyle[maxWidthMQ][key] = pxToVw(workingStyle[key]);
    } else if (fontPropertiesToAdapt.includes(key)) {
      if (noMediaQuery) adaptedStyle[key] = pxToVw(workingStyle[key]);
      else adaptedStyle[maxWidthMQ][key] = pxToVw(workingStyle[key]);
    }
  });

  if (debug) console.log(adaptedStyle); // tslint:disable-line

  return adaptedStyle;
};
