import React from 'react';
import Helmet from 'react-helmet';

type Props = {
  title?: string;
};

export const Meta = (props: Props) => (
  <Helmet>
    <title>{props.title ? `${props.title} | ` : ''}Face37</title>
  </Helmet>
);
