export const SITE_PADDING_LEFT = 21;
export const SITE_PADDING_LEFT_MOBILE = 15;
export const SITE_PADDING_TOP = 29;
export const SITE_PADDING_TOP_MOBILE = 21;
export const PAGE_MARGIN_TOP = 191;
export const PAGE_MARGIN_TOP_LARGE = 214;
export const PAGE_MARGIN_TOP_MOBILE = 140;

export const INNER_MARGIN = 30;
export const INNER_MARGIN_LEFT_MOBILE = 17;
export const INNER_MARGIN_RIGHT_MOBILE = 18;

export const BODY_FONT_SMALL_MOBILE = 14;
export const BODY_FONT_SMALL = 20;
export const BODY_FONT_LARGE = 40;

export const BODY_LETTER_SPACING_LARGE = 1;
