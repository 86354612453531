import * as React from 'react';

function Svg11(props) {
  return (
    <svg
      id="11_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 239.28 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24zM202.05 45.77c0 10.48-7.75 16.19-18.57 16.19-9.63 0-15.85-4.52-19-13.97l4.86-1.45c2.39 8.01 7.58 10.82 14.31 10.82 8.09 0 13.55-4 13.55-11.76 0-7.75-5.54-12.86-13.89-12.86h-3.32v-4.43h2.47c7.24 0 13.46-4.51 13.46-12.61 0-7.67-5.54-10.99-12.18-10.99-8.09 0-12.44 4.26-12.44 10.31 0 1.87.43 3.41 1.45 5.28l-4.69 1.7c-1.19-2.3-1.62-4.6-1.62-6.73 0-8.69 7.07-15.16 17.55-15.16 9.46 0 16.78 6.22 16.78 15.93 0 6.9-3.66 11.08-9.71 14.06 6.14 2.38 10.99 7.32 10.99 15.67zM230.93 1.13c5.54 0 8.35 2.47 8.35 7.92 0 14.14-24.28 26.49-24.28 52.05h-4.77c0-27.18 24.19-39.53 24.19-52.22 0-2.3-1.28-3.15-4.17-3.15H202.9v-4.6h28.03z" />
    </svg>
  );
}

export default Svg11;
