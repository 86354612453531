import * as React from 'react';

function Svg07(props) {
  return (
    <svg
      id="07_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 250.19 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.07_svg__st0{fill:#020203}'}</style>
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24z" />
      <path fill={props.fill}
        
        d="M165.59 59.55V55.9H194c8.19 0 10.33-2.23 10.33-10.95 0-5.97-2.58-10.95-10.33-10.95h-8.55c-6.95 0-12.56.53-12.56-4.63 0-4.27 4.72-6.32 8.64-8.73l12.11-7.39c5.97-3.65 8.99-3.29 8.99-5.7 0-3.38-1.34-1.16-6.86-1.16h-28.5V1.13h27.25c8.19 0 9.88 3.03 9.88 6.41 0 4.19-3.38 7.12-7.75 9.62l-11.31 6.59c-7.48 4.36-10.6 2.58-10.6 5.88 0 2.58 4.36-.89 11.13-.89h5.08c9.62 0 15.23 7.57 15.23 16.21 0 11.4-5.25 16.21-15.23 16.21h-9.88c-7.91-.01-12.09-.46-15.48-1.61zM249.66 8.52l-20.84 52.63h-6.59l18.26-43.9c3.12-7.57 7.57-9.97 7.57-11.93 0-.89-1.16-2.05-2.32-2.05-1.87 0-3.65 3.12-12.65 3.12C219.2 6.38 220.36 3 217.07 3c-3.65 0-4.36 1.87-4.36 4.27 0 .89.18 2.49.71 9.35h-5.25c-.44-2.85-.53-4.01-.53-5.08 0-4.81 2.23-10.42 12.11-10.42h23.87c4.1 0 6.59 2.14 6.59 4.99-.02.72-.2 1.52-.55 2.41z"
      />
    </svg>
  );
}

export default Svg07;
