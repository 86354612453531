import * as React from 'react';

function Svg05(props) {
  return (
    <svg
      id="05_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 242.22 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.05_svg__st0{fill:#020203}'}</style>
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24z" />
      <path fill={props.fill}
        
        d="M180.49 59.22v2.74c-9.67 0-15.93-6.25-15.93-12.67 0-3.17 1.8-5.05 4.2-5.05s4.28 1.8 4.28 4.2c0 2.48-1.88 4.28-4.28 4.28-.43 0-.77-.09-1.11-.17 2.05 3.93 6.59 6.67 12.84 6.67zM171.5 11.1h-2.74V1.17h31.08l-3.6 5.39H171.5v4.54zm22.26-.94L181.43 29h-3.42l12.33-18.84h3.42zm8.13 33.3c0 10.7-8.65 18.49-17.81 18.49v-2.74c5.91 0 11.39-4.2 11.39-10.7v-9.33c0-5.99-4.71-9.33-9.67-10.02l1.54-2.48c7.7 1.37 14.55 8.05 14.55 16.78zM205.32 1.17h27.48l-.86 2.74h-23.89v9.16h-2.74V1.17zm14.21 59.93h-5.82L236.4 1.17h5.82L219.53 61.1z"
      />
    </svg>
  );
}

export default Svg05;
