export const tinyMobileMaxWidth = '568px';

export const mobileMaxWidth = '740px';
export const mobileMaxWidthNumber = 740;
export const smallMobileMaxHeight = '700px';
export const tinyMobileMaxHeight = '500px';

export const desktopMinWidth = '740px';
export const desktopMaxWidthNumber = 740;
export const desktopMaxWidth = '740px';
