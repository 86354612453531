import { observable } from 'mobx';

const DEFAULT_BACKGROUND_COLOR = '#ffffff';
const DEFUALT_FOREGROUND_COLOR = '#000000';

class UIStore {
  @observable logoIndex = 0;
  @observable backgroundColor = DEFAULT_BACKGROUND_COLOR;
  @observable foregroundColor = DEFUALT_FOREGROUND_COLOR;
  @observable mobileMenuVisible = false;

  setupPage = (backgroundColor?: string, foregroundColor?: string) => {
    this.logoIndex = (this.logoIndex + 1) % 20;

    this.backgroundColor = backgroundColor || DEFAULT_BACKGROUND_COLOR;
    this.foregroundColor = foregroundColor || DEFUALT_FOREGROUND_COLOR;
  };

  showMobileMenu = () => {
    this.mobileMenuVisible = true;
  };

  hideMobileMenu = () => {
    this.mobileMenuVisible = false;
  };
}

export default new UIStore();
