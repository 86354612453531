import {
  desktopMinWidth,
  desktopMaxWidth,
  mobileMaxWidth,
  mobileMaxWidthNumber,
  smallMobileMaxHeight,
  tinyMobileMaxWidth,
  tinyMobileMaxHeight,
} from './breakpoints';

export const mobileMQ = `@media only screen and (max-width: ${mobileMaxWidth})`;
export const smallMobileMQ = `@media only screen and (max-height: ${smallMobileMaxHeight})`;
export const tinyMobileMQ = `@media only screen and (max-width: ${tinyMobileMaxWidth}) and (max-height: ${tinyMobileMaxHeight})`;

export const fullWidthMQ = `@media only screen and (min-width: ${desktopMinWidth})`;
export const maxWidthMQ = `@media only screen and (min-width: ${desktopMaxWidth})`;

export const isMobile = () => {
  return (
    typeof window !== 'undefined' && window.innerWidth <= mobileMaxWidthNumber
  );
};

export const isIPhone = () => {
  const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  return isMobile() && iOS;
};
