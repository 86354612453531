import * as React from 'react';

function Svg14(props) {
  return (
    <svg
      id="14_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 241.36 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24zM164.69 47.08l3.94-1.09c1.44 7.04 6.49 12.32 14.6 12.32 8.25 0 14.87-5.15 14.87-14.28 0-8.71-5.86-13.7-15.89-13.7v-3.49c8.19 0 13.64-4.12 13.64-11.7 0-6.34-4.48-11.2-12.04-11.2-7.02 0-12.17 4.6-13.46 11.3l-3.79-.78c1.59-8.7 8.41-14.42 17.29-14.42 9.77 0 16.04 6.13 16.04 14.73 0 6.09-2.94 11.02-9.17 13.29v.58c6.85 1.43 11.54 7.65 11.54 15.58 0 11.17-8.24 18.02-19.09 18.02-10.06 0-16.7-6.44-18.48-15.16zM202.43 1.17h38.93v3.81l-25.88 56.17h-4.37l25.28-54.9c.26-.54.68-.68.92-.68v-.6h-34.88v-3.8z" />
    </svg>
  );
}

export default Svg14;
