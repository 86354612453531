import React, { Fragment, useRef, useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import ReactGA from 'react-ga';
import { createGlobalStyle } from 'styled-components';

import { CONTENT_URL } from '@config/urls';
import UIStore from '@stores/UIStore';
import { adaptForLargeScreen, pxToVw } from '@utils/adaptForLargeScreen';
import { Header } from '@components/Header';
import { MobileMenu } from '@components/MobileMenu';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: F37-Beckett-DemiBold;
  font-display: block;
  src: url(/fonts/F37Beckett-Demi.woff2) format('woff2'),
    url(/fonts/F37Beckett-Demi.woff) format('woff');
}

@font-face {
  font-family: F37-Beckett-DemiBoldItalic;
  font-display: block;
  src: url(/fonts/F37Beckett-DemiIt.woff2) format('woff2'),
    url(/fonts/F37Beckett-DemiIt.woff) format('woff');
}

@font-face {
  font-family: F37-Beckett-Bold;
  font-display: block;
  src: url(/fonts/F37Beckett-Bold.woff2) format('woff2'),
    url(/fonts/F37Beckett-Bold.woff) format('woff');
}

@font-face {
  font-family: F37-Beckett-Thin;
  font-display: block;
  src: url(/fonts/F37Beckett-Thin.woff2) format('woff2'),
    url(/fonts/F37Beckett-Thin.woff) format('woff');
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

html.js-enabled:not(.js-loaded) {
  display: none;
}

html,
body,
body > div > div, /* no idea why this div is here */
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-text-size-adjust: 100%;
}

html {
  font-family: F37-Beckett-DemiBold;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
}
`;

const Container = styled.div<{
  backgroundColor: string;
  foregroundColor: string;
}>(p =>
  adaptForLargeScreen({
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',

    backgroundColor: p.backgroundColor,
    color: p.foregroundColor,

    fontFamily: 'F37-Beckett-DemiBold',
    fontSize: 14,
    lineHeight: 1.25,
    letterSpacing: 0.5,

    display: 'flex',
  }),
);

export const Layout = observer(props => {
  const { homepageFonts } = props;
  // const location = useLocation();

  useEffect(() => {
    document.documentElement.classList.add('js-loaded');
    ReactGA.initialize('UA-1603217-2');

    window.onerror = e => ReactGA.exception({ description: e.toString() });
  }, []);

  // useEffect(() => {
  //   if (location) {
  //     ReactGA.pageview(location.pathname);
  //   }
  // }, [location]);

  return (
    <Fragment>
      <GlobalStyle />
      <Helmet>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        <meta
          name="description"
          content="Face37 is an award winning graphic design studio, servicing a global roster of clients from its Manchester base. Led by Rick and Annabel Banks, the duo have helped create some of the world's most loved brands, including EE, Triumph Motorcycles, MLS, and British Heart Foundation."
        />

        <meta property="og:url" content="http://face37.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Face37" />
        <meta
          property="og:description"
          content="Face37 is an award winning graphic design studio, servicing a global roster of clients from its Manchester base. Led by Rick and Annabel Banks, the duo have helped create some of the world's most loved brands, including EE, Triumph Motorcycles, MLS, and British Heart Foundation."
        />
        <meta
          property="og:image"
          content="http://face37.com/images/social.png"
        />

        <style type="text/css">
          {homepageFonts
            .map(
              font => `@font-face {
          font-family: '${font.fontName}';
          src: url("${CONTENT_URL}/${font.woff2File}") format('woff2'),
            url("${CONTENT_URL}/${font.woffFile}") format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: block;
        }
        
        @font-face {
          font-family: '${font.fontName} Bold';
          src: url("${CONTENT_URL}/${font.boldWoff2File}") format('woff2'),
            url("${CONTENT_URL}/${font.boldWoffFile}") format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: block;
        }`,
            )
            .join('\n\n')}
        </style>

        <style type="text/css">{`
          body {
            background-color: ${UIStore.backgroundColor};
          }
        `}</style>

        <script>{`
          document.documentElement.classList.add("js-enabled");

          setTimeout(() => {
            if(!document.documentElement.classList.contains("js-loaded")) {
              document.documentElement.classList.remove("js-enabled");
            }
          }, 2000);
        `}</script>
      </Helmet>

      <Container
        foregroundColor={UIStore.foregroundColor}
        backgroundColor={UIStore.backgroundColor}
      >
        <Header />
        {props.children}

        {UIStore.mobileMenuVisible && <MobileMenu />}
      </Container>
    </Fragment>
  );
});
