import * as React from 'react';

function Svg17(props) {
  return (
    <svg
      id="17_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 250.83 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24zM206.92 40.57v7.66c0 5.39-2.95 12.88-13.05 12.88H166V47.05h21.64c2.27 0 3.45-1.18 3.45-3.37v-2.86c0-2.19-1.18-3.45-3.45-3.45h-19.12V23.31h18.27c2.27 0 3.45-1.26 3.45-3.45V18.6c0-2.19-1.18-3.37-3.45-3.37h-19.96V1.16h26.19c10.1 0 13.05 7.49 13.05 12.88v6.06c0 3.45-1.18 7.49-4.8 9.94 4.22 2.45 5.65 6.83 5.65 10.53zm-3.36-20.46v-6.06c0-6.48-3.96-10.53-10.53-10.53h-23.66v9.35h17.43c3.62 0 5.98 2.19 5.98 5.73v1.26c0 3.54-2.36 5.81-5.98 5.81h-15.75v9.35h16.59c3.62 0 5.98 2.19 5.98 5.81v2.86c0 3.54-2.36 5.73-5.98 5.73h-19.12v9.35h25.35c6.57 0 10.53-4.04 10.53-10.53v-7.66c0-4.88-2.78-9.01-6.91-10.53 3.71-1.35 6.07-5.39 6.07-9.94zM209.36 1.16h29.81c9.35 0 14.15 6.48 10.36 18.02l-15.07 41.94h-16.84l15.92-43.79c.51-1.43-.08-2.1-1.18-2.1h-22.99V1.16zm22.99 11.71c3.03 0 4.63 2.36 3.62 5.22l-14.82 40.67h11.54l14.48-40.33c3.03-9.43.08-14.91-8-14.91h-27.28v9.35h20.46z" />
    </svg>
  );
}

export default Svg17;
