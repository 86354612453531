import * as React from 'react';

function Svg09(props) {
  return (
    <svg
      id="09_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 239.25 62.27"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.09_svg__st0{fill:#020203}'}</style>
      <path fill={props.fill} d="M162.37 51.28h-22.81V35.84h22.81v-9.86h-22.81V11.14h22.81V1.19H129v13.7C122.93 5.29 113.54.03 102.38.03c-8.29 0-16.05 3.22-21.85 9.07-3.57 3.6-6.15 7.95-7.6 12.7V1.19h-9.77L39.21 35.23c-5.42-2.87-10.78-6.23-16.33-8.17V11.21H43.6V1.19H12.34V25c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.88-.69-3.08-2.57-3.08-4.71 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.19c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.15v-25.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.43zm94.89-13.66l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24z" />
      <path fill={props.fill}
        
        d="M182.68 62.26c-9.06 0-16.53-7.38-16.53-16.53 0-1.01.08-2.01.25-2.94l3.61.67c-.17.84-.25 1.76-.25 2.27 0 7.38 5.79 12.92 12.92 12.92 6.96 0 12.75-5.54 12.75-12.92 0-3.52-1.09-6.46-3.44-8.89l2.6-2.6c3.02 3.1 4.7 7.22 4.7 11.5.01 9.14-7.46 16.52-16.61 16.52zm0-31.47v-3.61c6.54 0 11.83-5.29 11.83-11.83 0-6.29-5.29-11.75-11.83-11.75-6.29 0-11.75 5.45-11.75 11.75h-3.61c0-8.48 6.88-15.36 15.36-15.36 8.56 0 15.44 6.88 15.44 15.36s-6.88 15.44-15.44 15.44zM239.25 1.76l-1.59 3.61h-36.92V1.76h38.51zm-4.95 11.58L214.33 60.5h-3.94l19.72-47.16h4.19z"
      />
    </svg>
  );
}

export default Svg09;
