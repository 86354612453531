import * as React from 'react';

function Svg10(props) {
  return (
    <svg
      id="10_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 269.47 62.24"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path fill={props.fill} d="M162.37 51.25h-22.81V35.81h22.81v-9.86h-22.81V11.11h22.81V1.16H129v13.7C122.93 5.26 113.54 0 102.38 0 94.1 0 86.34 3.22 80.54 9.07c-3.57 3.6-6.15 7.95-7.6 12.7V1.16h-9.77L39.21 35.2c-5.42-2.87-10.78-6.23-16.33-8.17V11.18H43.6V1.16H12.34v23.81c-.6-.09-1.29-.09-1.88-.09-6.68 0-10.45 3-10.45 7.37 0 3.08 1.71 5.14 4.71 5.31-1.89-.68-3.09-2.56-3.09-4.7 0-3.26 3-4.8 6.43-4.8 2.31 0 4.28.77 4.28.77v32.3h10.54v-.03h10.79l13.15-18.81c1.65.26 3.27.42 4.84.42 6.34 0 8.05-2.74 8.05-2.74s-1.71.43-3.94.43c-2.35 0-4.64-.4-6.9-1.05l13.56-19.4.03 41.16h10.47V40.16c1.45 4.74 4.02 9.11 7.58 12.78 5.83 6 13.57 9.3 21.78 9.3 11.1 0 21.01-5.6 26.7-15.03v13.9h33.37v-9.86zm-139.5 7.16V33.2c4.02 2.09 8.65 4.36 13.41 6.15L22.87 58.41zm94.89-13.67l-.32.37c-4.21 4.82-9.27 7.26-15.06 7.26-10.93 0-20.17-9.79-20.17-21.38 0-11.65 9.13-21.12 20.34-21.12 5.99 0 11.05 2.54 15.48 7.77l.32.38H129v26.72h-11.24zM188.19 61.12h.09-8.39.09c-10.45 0-16.19-7.45-16.19-16.27h8.05c0 7.71 3.51 14.3 12.25 15.93 1.63-.34 3.08-.77 4.37-1.37-5.48-2.74-8.39-8.31-8.39-14.56h8.05c0 4.62 1.29 8.82 4.11 11.82 2.91-2.83 4.11-6.85 4.11-10.96 0-8.91-7.37-10.54-7.37-14.73s7.37-5.48 7.37-14.39c0-4.11-1.2-8.05-4.03-10.96-2.91 3-4.2 7.2-4.2 11.82h-8.05c0-6.25 2.91-11.82 8.39-14.56-1.29-.6-2.74-1.03-4.37-1.37-8.74 1.63-12.25 8.22-12.25 15.93h-8.05c0-8.82 5.74-16.27 16.19-16.27h-.09 8.39-.09c10.45 0 16.19 7.45 16.19 15.42 0 8.91-7.37 10.19-7.37 14.39s7.37 5.82 7.37 14.73c.01 7.95-5.73 15.4-16.18 15.4zm25.1-30.15c0 4.2 7.37 5.82 7.37 14.73 0 7.97-5.74 15.42-16.27 15.42h-8.22c11.82 0 16.45-7.45 16.45-15.42 0-8.91-7.37-10.54-7.37-14.73s7.37-5.48 7.37-14.39c0-7.97-4.63-15.42-16.45-15.42h8.22c10.54 0 16.27 7.45 16.27 15.42-.01 8.91-7.37 10.19-7.37 14.39z" />
      <path fill={props.fill} d="M234.1 28.74c-9.59 1.2-13.45 8.14-13.45 16.96v15.42h-8.05V45.7c0-9.68 5.74-17.13 16.27-17.13h2.83c9.59-1.2 13.45-8.14 13.45-16.96V1.16h8.05v10.45c0 9.68-5.74 17.13-16.27 17.13h-2.83zm-13.45-11.3h-8.05V1.16h8.05v16.28zm48.82-16.28v10.45c0 9.68-5.74 17.13-16.27 17.13h-2.83c-9.59 1.2-13.45 8.14-13.45 16.96v15.42h-8.05V45.7c0-9.68 5.74-17.13 16.27-17.13h2.83c9.59-1.2 13.45-8.14 13.45-16.96V1.16h8.05zm-32.55 16.28h-8.05V1.16h8.05v16.28z" />
    </svg>
  );
}

export default Svg10;
